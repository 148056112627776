import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { global } from './global';

@Injectable({
  providedIn: 'root'
})
export class SpecialityService {

  public url: String;
 
  constructor(private _http: HttpClient) {
    this.url = global.url
  }

  speciality(speciality): Observable<any> {
    const headers = new HttpHeaders().set(
      'Content-Type', 'application/json'
    );
    return this._http.post(`${this.url}speciality`, speciality, { headers: headers });
  }

  getSpecialities(): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.get(`${this.url}specialities`, { headers: headers });
  }

  getSpecialitiesByBranch(id): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.get(`${this.url}specialities/${id}`, { headers: headers });
  }

  deleteSpeciality(data): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.delete(`${this.url}speciality/${data._id}`, { headers: headers });
  }

}
