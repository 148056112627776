import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { global } from './global';
import { Observable } from 'rxjs';
import {map, tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  public url: String;
  private flag: boolean;

  constructor( 
    private _http: HttpClient
  ) {
    this.url = global.url;
  }

  charge(token): Observable<any> {
    
    return this._http.post(`${this.url}subscription`, {token:token});
  }

  confirmSuscription(): Observable<any>{
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.get(`${this.url}verifySubscription`, { headers: headers }).pipe(
      tap(
        (res)=>{
          this.saveSubscription(res.flag)
        }
      )
    )
  }
  saveSubscription(flag:boolean){
    this.flag = flag
  }
  getSub(){
    return this.flag
  }
}
