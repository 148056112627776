<div class="container-fluid mt-4">
    <div class="row">
        <div class="col-11 mx-auto">
            <div class="row">
                <div class="col-12">
                    <div class="card shadow-sm mb-4 bg-white rounded">
                        <div class="card-body">
                            <div class="d-flex justify-content-center">
                                <span class="font-style">Lista de pacientes <i class="fas fa-procedures"></i></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-4">
                    <mat-form-field>
                        <mat-label>buscar</mat-label>
                        <input matInput (keyup)="applyFilter($event)" placeholder="Realice su busqueda">
                    </mat-form-field>
                </div>

            </div>
            <div class="row">
                <div class="col-12">
                    <div class="mat-elevation-z8">
                        <table mat-table [dataSource]="dataSource">
                            <!-- ID Column -->
                            <ng-container matColumnDef="Nombre">
                                <th mat-header-cell *matHeaderCellDef> Nombre </th>
                                <td mat-cell *matCellDef="let row"> {{row.name}} </td>
                            </ng-container>
                            <!-- Progress Column -->
                            <ng-container matColumnDef="Edad">
                                <th mat-header-cell *matHeaderCellDef> Edad </th>
                                <td mat-cell *matCellDef="let row"> {{row.age}} </td>
                            </ng-container>

                            <!-- Name Column -->
                            <ng-container matColumnDef="Telefono">
                                <th mat-header-cell *matHeaderCellDef> Telefono </th>
                                <td mat-cell *matCellDef="let row"> {{row.phone}}</td>
                            </ng-container>

                            <ng-container matColumnDef="Email">
                                <th mat-header-cell *matHeaderCellDef> Email </th>
                                <td mat-cell *matCellDef="let row"> {{row.email}} </td>
                            </ng-container>

                            <ng-container matColumnDef="Patologia">
                                <th mat-header-cell *matHeaderCellDef> Patologia </th>
                                <td mat-cell *matCellDef="let row"> {{row.description}} </td>
                            </ng-container>

                            <ng-container matColumnDef="Fecha">
                                <th mat-header-cell *matHeaderCellDef> Fecha </th>
                                <td mat-cell *matCellDef="let row"> {{row.date}} </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row;  columns: displayedColumns;"></tr>
                        </table>


                        <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
                    </div>

                </div>
            </div>



        </div>
    </div>
</div>