import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-medical-branches',
  templateUrl: './medical-branches.component.html',
  styleUrls: ['./medical-branches.component.css']
})
export class MedicalBranchesComponent implements OnInit {

  @Input() branch;

  constructor(private router: Router) { }

  ngOnInit(): void {
    
  }
  open(e){
    this.router.navigate(['speciality', e]);
  }

}
