<div class="modal fade" id="login" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <div class="w-100 d-flex justify-content-center">
          <h5 class="modal-title dispaly-4" id="exampleModalLabel">Inicia Sesión</h5>
        </div>
        <button #closebutton (click)="reset()" type="button" class="close ml-0" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div *ngIf="alert.flag" class="alert alert-danger text-center" role="alert">
          {{alert.message}}
        </div>
        <form [formGroup]="signinForm" (ngSubmit)="onSubmit(signinForm.value)">
          <div class="form-group">
            <label for="emailLogin"><i class="fas fa-envelope"></i> Correo electronico:</label>
            <input formControlName="email" type="email" class="form-control" id="emailLogin"
              aria-describedby="emailHelp" placeholder="Ingrese su correo">
            <small *ngIf="signinForm.get('email').hasError('required') && signinForm.get('email').touched"
              class="text-danger">Este campo es requerido</small>
            <small *ngIf="signinForm.get('email').hasError('email')" class="text-danger">E-mail no valido</small>
          </div>
          <div class="form-group">
            <label for="passwordLogin"><i class="fas fa-key"></i> Contraseña:</label>
            <input formControlName="password" type="password" class="form-control" id="passwordLogin"
              placeholder="Ingrese su contraseña">
              <small *ngIf="signinForm.get('password').hasError('required') && signinForm.get('password').touched"
              class="text-danger">Este campo es requerido</small>
          </div>

          <button [disabled]="signinForm.invalid" type="submit" class="btn btn-success w-100">Iniciar sesión</button>
        </form>
      </div>
      <div class="modal-footer d-flex justify-content-center">
        <a href=""><small class="form-text text-muted">¿Olvidó su contraseña?</small></a>
      </div>
    </div>
  </div>
</div>