import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from "./services/user.service";
import { NotificationService } from "./services/Notification.service";
import { PusherService } from './services/pusher.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [UserService, NotificationService, PusherService]
})
export class AppComponent implements OnInit {
  public token:boolean = false;
  public idDoctor: string;
  public notifications: any[] = [];
  public totalNotifications: number = 0;
  public avatar: string = "";
  title = 'agendaMedica-frontend';

  constructor(
    private _router: Router,
    public _authService: UserService,
    public _notificationService: NotificationService,
    private _pusherService: PusherService
  ){
    this.token = true;
  }

  ngOnInit(){
    this.getLoggedUser();
    this.getNotificationsByDoctor();
    this.pusher(`channel-notification-${this.idDoctor}`, `new-notification-${this.idDoctor}`);
    this.pusher(`channel-read-${this.idDoctor}`, `read-notification-${this.idDoctor}`);
  }

  getLoggedUser(){
    
    let user = this._authService.getLoggedUser();
    this.avatar = user.avatar;
    this.idDoctor = user._id;
  }

  getNotificationsByDoctor(){
    if(!this.idDoctor) return;
    this._notificationService.notifications(this.idDoctor, 200).subscribe(
      res => {
        this.notifications = res.data[0].notifications;
        
        this.totalNotifications = res.data[0].total.some(d => d.count) ? res.data[0].total[0].count : 0;
      },
      err => {
        console.log(err); 
      }
    )
  }

  pusher(chanel, event){
    if(this.idDoctor){
      this._pusherService.subScribeToChannel(chanel, [event], (data) => {
        switch(event){
          case `new-notification-${this.idDoctor}`:
            this.notifications.unshift(data.notification);
            this.totalNotifications = (this.totalNotifications + 1);
            break;
          case `read-notification-${this.idDoctor}`:
            this.totalNotifications = (this.totalNotifications - 1);
            let index = this.notifications.findIndex(notification => notification._id == data.idNotification);
            this.notifications[index].read = true;
            break;
        }
      });
    }
  }

  selectedNotification(idNotification, idDoctor){
    this.notifications.find((notification) => {
      if(notification._id == idNotification && notification.read == false){
        notification.read = true;
        this._notificationService.updateNotification(idNotification, idDoctor).subscribe(
          res => {
            
          },
          err => {
            console.log(<any>err);
          }
        );
      }
    });
    this._router.navigate(['/tableMedical', idDoctor]);
  }

  handdle(){
    this._router.navigate(['/register']);
  }

  config(){
    let user = this._authService.getLoggedUser()._id;

    this._router.navigate(['medicalConfiguration',user]) 
  }
}
