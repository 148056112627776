import { Component, OnInit, ViewChild, OnChanges } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MedicalBranchService } from '../../services/medical-branch.service';
import { ToastrService } from 'ngx-toastr';

export interface branchData {
  name: string;
  description: string;
}

@Component({
  selector: 'app-admin-branch',
  templateUrl: './admin-branch.component.html',
  styleUrls: ['./admin-branch.component.css']
})
export class AdminBranchComponent implements OnInit, OnChanges {

  dataResult: any[] = [];
  displayedColumns: string[] = ['name', 'description','action'];
  dataSource: MatTableDataSource<branchData>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private _branchService: MedicalBranchService,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this.getData();
  }
  ngOnChanges() {
    this.editData('data')
  }

  getData() {
    this._branchService.getBranches().subscribe(
      response => {
        
        this.dataResult = response.branches;
        this.dataSource = new MatTableDataSource(this.dataResult);
      },
      error => {
        console.log(error);
      }
    )
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  editData(data) {
    console.log(data);
    
  }
  deleteData(data) {
    this._branchService.delete(data).subscribe(
      response => {
        console.log(response);
        
        this.toastr.success(`${response.menssage} se ha registrado exitosamente`); 
        this.getData();
      },
      error => {
        console.log(error);
      }
    )
 
  }
  
  refreshTable() {
    this.getData();
  }
}
   