import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MedicalBranchService } from '../../services/medical-branch.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-admin-modal-branch',
  templateUrl: './admin-modal-branch.component.html',
  styleUrls: ['./admin-modal-branch.component.css']
})
export class AdminModalBranchComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  public branchForm: FormGroup;
  public IsmodelShow: boolean = true;
  public classValidName: string;
  public classValidDescription: string;
  public classValidImage: string;
  @Output() refreshTable = new EventEmitter<any>();

  constructor(
    private _branchService: MedicalBranchService, 
    private _builder: FormBuilder,
    private toastr: ToastrService) {
    this.branchForm = this._builder.group({
      name: ['', Validators.required],
      description: ['', Validators.compose([Validators.required])],
      image: [null, Validators.required],
    })
    this.classValidName = "form-control"
    this.classValidDescription = "form-control"
    this.classValidImage = "custom-file-label"

  }

  ngOnInit(): void {
  }

  selectImage(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      console.log(file);
      
      this.branchForm.controls['image'].setValue(file);
    }
    this.validClassForm('image'); 

  }

  onSubmit(form) {
    
    const formData = new FormData();
    formData.append('image', form.image);
    formData.append('name',form.name);
    formData.append('description', form.description);

    this._branchService.branch(formData).subscribe(
      response => {
        this.closebutton.nativeElement.click(); 
        this.refreshTable.emit();
        this.toastr.success(`${response.branch.name} se ha registrado exitosamente`);   
      },
      error => {
        console.log(error);  
      }
    )
  }



  reset() {
    this.classValidName = "form-control";
    this.classValidDescription = "form-control";
    this.classValidImage = "custom-file-label";
    this.branchForm.reset();
  }

  validClassForm(param) {
    switch (param) {
      case 'name':
        if (this.branchForm.get('name').hasError('required') && this.branchForm.get('name').touched) {
          this.classValidName = "form-control is-invalid"
        } else {
          this.classValidName = "form-control is-valid"
        }
        break;
      case 'description':
        if (this.branchForm.get('description').hasError('required') && this.branchForm.get('description').touched) {
          this.classValidDescription = "form-control is-invalid"
        } else {
          this.classValidDescription = "form-control is-valid"
        }
        break;
      case 'image':
        if (this.branchForm.value.image != null) {
          this.classValidImage = "custom-file-label is-valid"
        } else {
          this.classValidImage = "custom-file-label is-invalid"
        }
        break;
    }
  }


  onTouchClass( param): void {
    this.validClassForm(param);
  }

}
