import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PaymentService } from '../services/payment.service';
import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root'
})
export class SuccessPaymentGuard implements CanActivate {
  public idUser: string = '';
  constructor(
    private _router: Router,
    private _paymentService: PaymentService,
    private _authService: UserService,
  ) {

  }
  canActivate(): Observable<boolean> {
    return this._paymentService.confirmSuscription().pipe(
      map(res => {
        if (res.flag) {
          this.idUser = this._authService.getLoggedUser()._id;
          this._router.navigate(['tableMedical', this.idUser])
          return false;
        }
        return true;
        
      })
    )


  }
  
}
