import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { global } from './global';

@Injectable({
  providedIn: 'root'
})
export class MedicalBranchService { 

  public url: String;

  constructor(private _http: HttpClient) {
    this.url = global.url
  }

  branch(branch): Observable<any> {
    return this._http.post(`${this.url}branch`, branch);
  }

  getBranches(): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.get(`${this.url}branches`, { headers: headers });
  }

  delete(branch): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.delete(`${this.url}branch/${branch._id}`, { headers: headers }); 
  }
}
 