import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PublicityService } from 'src/app/services/publicity.service';
import { MedicalBranchService } from '../../../services/medical-branch.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  public branches:any[] = [];
  public publicities: any[] = [];
  constructor(
    private _branchService: MedicalBranchService,
    private _publicityService: PublicityService,
    private _router: Router
    ) { }

  ngOnInit(): void {
    this.getData();
    this.getPublicities();
  }

  getPublicities(){
    this._publicityService.getPublicities().subscribe(
      res => {
        this.publicities = res.publicities;

        this.publicities = this.publicities.map((pub, index) =>{
          if(index === 0){
            pub.class = 'carousel-item active'
          }else{
            pub.class = 'carousel-item'
          }
          return pub;
        })
        
      },
      error => {
        console.log(error);
        
      }
    )
  }

  getData(){
    this._branchService.getBranches().subscribe(
      response => {
        this.branches = response.branches;
      },
      error => {
        console.log(error);

      }
    )
  }

  navigateDoctor(user){
    this._router.navigate(['medical', user]);
    
  }

  open(){
    console.log("hola");
    
  }

}
