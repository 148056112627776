<div class="modal fade" id="specialityModal" tabindex="-1" aria-hidden="true" data-backdrop="static">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <div class="w-100 d-flex justify-content-center">
          <h5 class="modal-title dispaly-4" id="branchModal">Nuevo elemento</h5>
        </div>
        <button type="button" class="close ml-0" #closebutton (click)="reset()" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body"> 

        <form [formGroup]="SpecialityForm" (ngSubmit)="onSubmit(SpecialityForm.value)">
 
          <div class="form-group">
            <label for="nameSpeciality"><i class="fas fa-code-branch"></i> Nombre de la especialidad médica:</label>
            <input type="text" formControlName="name" name="nameSpeciality" [class]="classValidName" id="nameSpeciality"
              placeholder="Ingrese la especialidad medica" (blur)="validClassForm('name')"
              (input)="onTouchClass('name')" (change)="validClassForm('name')">
            <small *ngIf="SpecialityForm.get('name').hasError('required') && SpecialityForm.get('name').touched"
              class="text-danger">Este campo es requerido</small>
          </div>

          <div class="form-group">
            <label for="medicalBranch">Rama médica</label>
            <select formControlName="branch" class="form-control" [class]="classValidBranch" id="medicalBranch"
              (blur)="validClassForm('branch')" (change)="validClassForm('branch')">
              <option *ngFor="let branchSelect of branches" [value]="branchSelect._id">
                {{branchSelect.name}}
              </option>
            </select>
          </div>

          <button [disabled]="SpecialityForm.invalid" type="submit" class="btn btn-success w-100">Registrar</button>
        </form>

      </div>
    </div>
  </div>
</div>