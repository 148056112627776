import { Component, OnInit, Output, EventEmitter, ViewChild, Input, OnChanges } from '@angular/core';
import { CalendarOptions, FullCalendarComponent } from '@fullcalendar/angular'; // useful for typechecking
import esLocale from '@fullcalendar/core/locales/es';
import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';
import { AppointmentService } from '../../../services/appointment.service'
import { PusherService } from '../../../services/pusher.service';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css'],
  providers: [AppointmentService, PusherService]
})
export class CalendarComponent implements OnInit, OnChanges {

  @Input() user: any;
  @Output() sendDate = new EventEmitter<string>();
  @ViewChild('calendar') calendarComponent: FullCalendarComponent;
  
  public date: string = '';
  public flagDate: boolean = false;
  public calendarEvents: any[] = [];
  public nowDateEvent: any;
  public pastDateEvent: any;
  public startWeekend: any;
  public detectionChange: boolean = true;
  public idDoctor: string;
  public calendarOptions: CalendarOptions = {
    //now: '2018-06-05',
    contentHeight: 'auto',
    allDaySlot: false,
    slotLabelInterval: '00:30:00',
    slotMinTime: '00:00',
    slotMaxTime: '00:00',
    slotDuration: "00:30:00",
    locale: esLocale,
    themeSystem: 'bootstrap',
    initialView: 'timeGridWeek',
    eventClick: this.eventClick.bind(this),
    dateClick: this.handleEventClick.bind(this),
    headerToolbar: {
      start  : '',
      center: 'title',
      end : 'next today',
    },
    titleFormat: {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    },
    dayHeaderFormat: {
      weekday: 'short',
      day: 'numeric', 
      omitCommas: true 
    },
    slotLabelFormat: {
      hour: 'numeric',
      minute: '2-digit',
      meridiem: 'short',
      hour12: true
    },
    events: this.calendarEvents
  };

  constructor(
    private _pusherService: PusherService,
    private _appointment: AppointmentService,
    private _route: ActivatedRoute
    ) {}

  ngOnInit(): void {
    this.getAppointmentsByDoctor();
    this.nowDateEvent = moment().locale('es').format('YYYY-MM-DD HH:mm');
    this.calendarEvents.push(
      {
        id: 'eventEnabled',
        daysOfWeek: ['1', '2', '3', '4', '5'],
        display: 'background',
        backgroundColor: '#ffffff'
      },
      {
        id: 'eventWeekend',
        daysOfWeek: ['0', '6'],
        display: 'background',
        backgroundColor: '#ff1744'
      }
    );
  }

  ngOnChanges(): void{
    if(this.user){
      this.pusher();
      this.calendarOptions.slotMinTime = this.user.initialHour.toString();
      this.calendarOptions.slotMaxTime = this.user.finalHour.toString();
    }
  }

  pusher(){
    this._pusherService.subScribeToChannel(`channel-appointment-${this.user._id}`, [`new-appointment-${this.user._id}`], (data) => {
      let startDate = moment(data.data.date, 'YYYY-MM-DD HH:mm a').locale('es-mx').format("YYYY-MM-DD HH:mm");
      let endDate = moment(data.data.date, 'YYYY-MM-DD HH:mm a').add(30, 'minutes').format("YYYY-MM-DD HH:mm");
      this.calendarEvents.push({
        id: 'idEvent',
        title: '!Agendad!',
        start: startDate,
        end: endDate,
        backgroundColor: 'rgba(0, 127, 167, 0.9)',
        borderColor: 'rgba(0, 127, 167, 0.9)'
      });
    });
  }

  getAppointmentsByDoctor() {
    this._route.params.subscribe((params) => {
      this.idDoctor = params.id;
      this._appointment.appointments(this.idDoctor).subscribe(
        req => {
          if(req.status == 200){
            req.appointments.map((appointment) => {
              let startDate = moment(appointment.date, 'YYYY-MM-DD HH:mm a').locale('es-mx').format("YYYY-MM-DD HH:mm");
              let endDate = moment(appointment.date, 'YYYY-MM-DD HH:mm a').add(30, 'minutes').format("YYYY-MM-DD HH:mm");
              this.calendarEvents.push({
                id: 'idEvent',
                title: '¡Agendado!',
                start: startDate,
                end: endDate,
                backgroundColor: 'rgba(0, 127, 167, 0.9)',
                borderColor: 'rgba(0, 127, 167, 0.9)'
              });
            });
          }
        },
        err => {
          console.log(err);
        }
      )
    })
  }

  eventClick(args){
    this.flagDate = true;
  }

  handleEventClick(args) {
    console.log(args);
    
    var isWeekend = false;
    let date = args.date.toString();
    let day = date.split(" ");
    switch(day[0].toString()){
      case 'Sat':
      case 'Sun':
        isWeekend = true;
        break;
      default:
        isWeekend = false;
        break;
    }
    moment.locale('es-mx')
    this.date = moment(args.dateStr).locale('es-mx').format('YYYY-MM-DD h:mm a');
    if(
        this.flagDate &&
        !moment(this.date, 'YYYY-MM-DD h:mm a').isBefore(moment().format('YYYY-MM-DD h:mm a')) &&
        !isWeekend
      ){
      this.sendDate.emit(this.date);
      this.flagDate = false;
    }
  }

  // rangeEventDisabled(){
  //   return {
  //     start: this.nowDateEvent,
  //     end: '2021-03-31'
  //   };
  // }
  
}


