<div class="container-fluid mt-4">
    <div class="row">
        <div class="col-md-11 mx-auto">
            <div class="row">
                <div class="col-md-12">
                    <div class="card shadow-sm mb-4 bg-white rounded">
                        <div class="card-body">
                            <div class="d-flex justify-content-center">
                                <span class="font-style">Publicidad <i class="fas fa-newspaper"></i></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="alert alert-primary text-center" role="alert">
                        Ahora es muy fácil que te des a conocer, unicamente selecciona la imagen publicitaria y da click
                        en comprar. Tu imagen aparecerá al inicio del sistema, de esta forma te daras a conocer.
                    </div>
                </div>

            </div>

            <div class="row mt-4">
                <div class="col-md-12">
                    <div class="card text-center shadow-sm mb-4 bg-white rounded">
                        <div class="card-header">
                            Mi Galería
                        </div>
                        <div class="card-body">
                            <div class="row d-flex justify-content-center">
                                <div *ngFor="let item of publicity" class="col-md-3 d-flex justify-content-center">
                                    <div  class="card shadow-sm mb-4 bg-white rounded mt-4" style="width: 18rem;">
                                        <img  [src]="item.image" class="card-img-top" width="500"
                                            height="200px">
                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>
                </div>
            </div>

            <div class="row mt-4">
                <div class="col-md-12">
                    <div class="card text-center shadow-sm mb-4 bg-white rounded">
                        <div class="card-header">
                            Selecciona tu imagen y realiza tu pago
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="custom-dropzone" ngx-dropzone [multiple]="false" [accept]="'image/*'"
                                        (change)="onSelect($event)">
                                        <ngx-dropzone-label>
                                            <div>
                                                <h2>Selecciona tu imagen</h2>
                                            </div>
                                        </ngx-dropzone-label>
                                        <ngx-dropzone-image-preview class="h-75 w-100" ngProjectAs="ngx-dropzone-preview"
                                            *ngFor="let f of files" [file]="f" [removable]="true" 
                                            (removed)="onRemove(f)">
                                        </ngx-dropzone-image-preview>
                                    </div>
                                </div>
                                <div class="col-md-6 align-self-center">
                                    <div>
                                        <div *ngIf="transactionError" class="alert alert-danger text-center"
                                            role="alert">
                                            {{message}}
                                        </div>
                                        <div>
                                            <div class="row mb-2">
                                                <div class="ml-3">Tarjeta de crédito/débito: </div>
                                                <div class="mr-3 ml-auto"> <img src="../../../assets/creditCards.png"
                                                        alt="" height="25px"></div>
                                            </div>

                                            <div class="credit-card form-control inputCard">
                                                <div>
                                                    <ngx-stripe-card [options]="cardOptions"
                                                        [elementsOptions]="elementsOptions">
                                                    </ngx-stripe-card>
                                                </div>
                                            </div>
                                        </div>

                                        <button *ngIf="loadigPayment===false" type="button"
                                            class="btn btn-info mt-4 h-50 w-100" [disabled]="files.length == 0" (click)="onClick()">Realizar
                                            pago</button>
                                        <button *ngIf="loadigPayment" type="button" class="btn btn-info h-20 mt-4 w-100"
                                            disabled>
                                            <div class="spinner-border text-secondary" role="status">
                                                <span class="sr-only">Loading...</span>
                                            </div>
                                        </button>
                                    </div>

                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>
</div>