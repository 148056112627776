<div class="mb-5">
  <div class="container">
    <h2 class="pt-5 mt-2 display-4 text-center"><strong>Nueva cita médica</strong></h2>
    <div class="alert alert-info text-center" role="alert">
      <strong>¡Aviso! </strong>Para poder agendar una cita médica es necesario que registre algunos datos que puden ser
      necesarios para que el proceso sea exitoso
    </div>
    <h2 class="pt-1"><strong>Datos generales del doctor</strong></h2>
    <hr class="featurette-divider">

    <!-- form getting data -->
    <div class="row pt-4">
      <div class="col-md-7">
        <div class="form-group">
          <label for="namedoc"><strong><i class="fas fa-user"></i> Nombre:</strong></label>
          <input type="text" class="form-control" id="namedoc" [value]="user.name" readonly>
        </div>
      </div>
      <div class="col-md-5">
        <div class="form-group">
          <label for="specialitydoc"><strong><i class="fas fa-graduation-cap"></i> Especialidad:</strong></label>
          <input type="text" class="form-control" id="specialitydoc" [value]="user.speciality.name" readonly>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label for="hospitaldoc"><strong><i class="fas fa-building"></i> Hospital:</strong></label>
          <input type="text" class="form-control" id="hospitaldoc" [value]="user.hospital" readonly>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label for="daydoc"><strong><i class="fas fa-calendar-day"></i> Dias de atención:</strong></label>
          <input type="text" class="form-control" id="daydoc" value="{{user.initialDay}} - {{user.finalDay}}" readonly>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label for="hourdoc"><strong><i class="fas fa-clock"></i> Horario de atención:</strong></label>
          <input type="text" class="form-control" id="hourdoc" value="{{user.initialHour}} - {{user.finalHour}}"
            readonly>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <label for="location"><strong><i class="fas fa-map-marker-alt"></i> Ubicación:</strong></label>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <app-map-location [lat]="user.lat" [lng]="user.lng"></app-map-location>
      </div>
    </div>
    <h2 class="pt-5 mt-4"><strong>Agendar una cita</strong></h2>
    <hr class="featurette-divider"> 
  </div>


  <div class="container-fluid">
    
    <div class="row m-sm-0 m-lg-5 p-sm-0 p-lg-5 pt-4">
      <div class="col-md-8">
        <div class="card customBorder shadow-sm p-3 mb-5 bg-white rounded">
          <div class="card-body">
            <app-calendar (sendDate)=getDateToChildren($event) [user]="sendUser"></app-calendar>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card shadow-sm mb-5 bg-white rounded">
          <div class="card-header">
            Datos del paciente
          </div>
          <div class="card-body">

            <div *ngIf="alert.flag" [ngClass]="['alert', 'text-center', 'text-white', alertMessage]" role="alert"
          style="background-color: rgba(0, 127, 167, 0.9);">
          {{alert.message}}
        </div>
        <form [formGroup]="appointmentForm" (ngSubmit)="onSubmit(appointmentForm)">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label for="namepac"><i class="fas fa-user"></i> Nombre:</label>
                <input formControlName="name" type="text" class="form-control" id="namepac"
                  placeholder="Nombre completo">
                <small *ngIf="appointmentForm.get('name').hasError('required') && appointmentForm.get('name').touched"
                  class="text-danger">
                  Este campo es requerido
                </small>

              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <label for="agepac"><i class="fas fa-pager"></i> Edad:</label>
                <input formControlName="age" type="number" class="form-control" id="agepac" placeholder="+18">
                <small *ngIf="appointmentForm.get('age').hasError('required') && appointmentForm.get('age').touched"
                  class="text-danger">
                  Este campo es requerido
                </small>
              </div>
            </div>
            <div class="col-md-8">
              <div class="form-group">
                <label for="phonepac"><i class="fas fa-phone"></i> Teléfono:</label>
                <input formControlName="phone" type="text" class="form-control" id="phonepac"
                  placeholder="Número telefónico">
                <small *ngIf="appointmentForm.get('phone').hasError('required') && appointmentForm.get('phone').touched"
                  class="text-danger">
                  Este campo es requerido
                </small>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label for="emailpac"><i class="fas fa-envelope"></i>Email:</label>
                <input formControlName="email" type="email" class="form-control" id="emailpac"
                  placeholder="ejemplo@ejemplo.com">
                <small *ngIf="appointmentForm.get('email').hasError('required') && appointmentForm.get('email').touched"
                  class="text-danger">
                  Este campo es requerido
                </small>
                <small
                  *ngIf="appointmentForm.get('email').hasError('email') && appointmentForm.get('email').hasError('pattern')"
                  class="text-danger">
                  E-mail no válido
                </small>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label for="datepac"><i class="fas fa-calendar-day"></i> Fecha de cita médica:</label>
                <input type="text" class="form-control" id="datepac" [(ngModel)]="appointment.date"
                  formControlName="date" readonly placeholder="Seleccione la fecha en el calendario.">
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label for="patologiapac"><i class="fas fa-procedures"></i> Motivo de consulta:</label>
                <textarea formControlName="description" class="form-control" id="patologiapac" rows="3"
                  placeholder="Ingrese una breve descripción del motivo de su consulta."></textarea>
                <small
                  *ngIf="appointmentForm.get('description').hasError('required') && appointmentForm.get('description').touched"
                  class="text-danger">
                  Este campo es requerido
                </small>
              </div>
            </div>
          </div>
          <button type="submit" class="btn btn-primary btnCustom w-100 align-items-center" [disabled]="appointmentForm.invalid"
            >Agendar cita</button>
        </form>

          </div>
        </div>

        
        
      </div>
    </div>
  </div>
</div>