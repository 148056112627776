<div class="container-fluid mb-5 position-absolute"
    [ngStyle]="{'background-image':url,'height': '65%','width': '100%', 'background-color': 'rgba(0,0,0,0.6)', 'filter':'brightness(0.7)'}">

</div>
<div class="container-fluid mb-5" style="height: 65%;">
    <div class="row h-100" >
        <div class="col-md-6">
            <div class="row h-50">
                <div class="align-self-end textDecoration ml-md-5 pl-md-5">
                    {{branch.name}}
                </div>
            </div>
            <div class="row h-50 d-none d-md-block">
                <div class="align-self-start textDecoration2 ml-5 pl-5">
                    {{branch.description}}
                </div>
            </div>
        </div>
        

        <div class="col-md-6 h-100">
            <div class="row h-50 d-flex justify-content-center">
                <div class="align-self-end">
                    
                        <div class="search textDecoration2">
                          <h1>Realiza tu busqueda</h1>
                          <h3>Haz click en el icono de busqueda!</h3>
                          <div>
                            <input type="text" placeholder="Search . . ." (input)="onSearchChange($event)">
                          </div>
                        </div>
                      
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container">
    <router-outlet></router-outlet> 
</div>