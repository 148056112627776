import { Injectable } from '@angular/core';
import { HttpInterceptor } from "@angular/common/http";
import { UserService } from "../services/user.service";

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor{

  constructor(
    private _authSerive: UserService
  ) { }

  intercept(req, next){
    const tokenizeReq = req.clone({
      setHeaders: {
        Authorization: `Bearer ${this._authSerive.getToken()}`
      }
    })
    return next.handle(tokenizeReq)
  }
}
