<div class="row d-flex justify-content-center animate__animated animate__fadeInUp">
    <div *ngFor="let user of users" class="col-sm-6 col-md-4 d-flex justify-content-center" style="cursor: pointer;" >
        <div class="w-100 card mt-4 shadow-sm p-3 mb-5 bg-white rounded-lg hvr-float" (click)="handdle(user)">
            <div class="row">
                <div class="col-md-4 my-auto">
                    <img class="avatar" [src]="user.avatar" alt="">
                </div>
                <div class="col-md-8">
                    <div class="card-body py-1 px-2">
                        <h5 class="card-title">{{user.name}}</h5>
                        <h6 class="card-subtitle mb-2 text-muted">{{user.speciality.name}}</h6>
                        <p class="card-text mb-1">{{user.address}}</p>
                        <div class="ec-stars-wrapper">
                            <form>
                                <p class="clasificacion mb-0">
                                    <input id="radio1" type="radio" name="estrellas" value="5">
                                    <label class="startSize" for="radio1">★</label>
                                    <input id="radio2" type="radio" name="estrellas" value="4">
                                    <label class="startSize" for="radio2">★</label>
                                    <input id="radio3" type="radio" name="estrellas" value="3">
                                    <label class="startSize" for="radio3">★</label>
                                    <input id="radio4" type="radio" name="estrellas" value="2">
                                    <label class="startSize" for="radio4">★</label>
                                    <input id="radio5" type="radio" name="estrellas" value="1">
                                    <label class="startSize" for="radio5">★</label>
                                </p>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
        
    </div>

    
    
</div>