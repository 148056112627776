import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-admin-table',
  templateUrl: './admin-table.component.html',
  styleUrls: ['./admin-table.component.css']
})
export class AdminTableComponent implements OnInit {

  displayedColumns: string[] = ['Nombre', 'Edad', 'Telefono', 'Email', 'Patologia', 'Fecha', 'Status'];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  dataResult: any[] = [{ "name": "juan", "age": 43, "phone": "234234234", "email":"michelle@hotmail.com", "pathology": "me duele la cabeza", "date": "2019-02-12" },
  { "name": "patric", "age": 43, "phone": "4921349217", "email":"michelle@hotmail.com", "pathology": "dolor de estomago", "date": "2019-02-12" },
  ];

  constructor() { }

  ngOnInit(): void {
    this.getData();
  }

  getData() {
    this.dataSource = new MatTableDataSource(this.dataResult);
    this.dataSource.paginator = this.paginator;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

}
