<div class="container mt-5">

    <form [formGroup]="registerForm" (ngSubmit)="onSubmit(registerForm.value)">
        <div class="row">
            <div class="col-md-2">
                <div class="custom-dropzone" ngx-dropzone [multiple]="false" [accept]="'image/*'"
                    (change)="onSelect($event)">
                    <ngx-dropzone-label>
                        <div>
                            <img class="avatar" [src]="avatar" height="40vh" width="40vh"/>
                        </div>
                    </ngx-dropzone-label>
                    <ngx-dropzone-image-preview class="h-75 w-100" ngProjectAs="ngx-dropzone-preview"
                        *ngFor="let f of files" [file]="f" [removable]="true" (removed)="onRemove(f)">
                    </ngx-dropzone-image-preview>
                </div>
            </div>
            <div class="col-md-10">
                <div class="form-group">
                    <label for="name"><i class="fas fa-user"></i> Nombre:</label>
                    <input type="text" formControlName="name" class="form-control" id="name"
                        placeholder="Ingrese su nombre completo">
                    <small *ngIf="registerForm.get('name').hasError('required') && registerForm.get('name').touched"
                        class="text-danger">Este campo es requerido</small>
                </div>

                <div class="form-group">
                    <label for="hospital"><i class="fas fa-building"></i> Hospital:</label>
                    <input formControlName="hospital" type="text" class="form-control" id="hospital"
                        placeholder="Ej: san Agustín">
                    <small
                        *ngIf="registerForm.get('hospital').hasError('required') && registerForm.get('hospital').touched"
                        class="text-danger">Este campo es requerido</small>
                </div>
            </div>
        </div>


        <div class="form-group">
            <label for="maplocation"><i class="fas fa-map-marker-alt"></i> Ubicación:</label>
            <input formControlName="address" class="form-control" ngx-google-places-autocomplete #placesRef="ngx-places"
                (onAddressChange)="handleAddressChange($event)" />
            <small *ngIf="registerForm.get('address').hasError('required') && registerForm.get('address').touched"
                class="text-danger">Este campo es requerido</small>
        </div>

        <div class="row">
            <div class="col-md-5">
                <div class="row">
                    <div class="col-md-12">
                        <div class="row">
                            <label for="day"><i class="fas fa-calendar-day"></i> Dias de atención:</label>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <select formControlName="initialDay" class="form-control" id="initialDay">
                                        <option selected>Lunes</option>
                                        <option>Martes</option>
                                        <option>Miercoles</option>
                                        <option>Jueves</option>
                                        <option>Viernes</option>
                                        <option>Sabado</option>
                                        <option>Domingo</option>
                                    </select>
                                    <small
                                        *ngIf="registerForm.get('initialDay').hasError('required') && registerForm.get('initialDay').touched"
                                        class="text-danger">Este campo es requerido</small>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <select formControlName="finalDay" class="form-control" id="finalDay">
                                        <option>Lunes</option>
                                        <option>Martes</option>
                                        <option>Miercoles</option>
                                        <option>Jueves</option>
                                        <option>Viernes</option>
                                        <option>Sabado</option>
                                        <option selected>Domingo</option>
                                    </select>
                                    <small
                                        *ngIf="registerForm.get('finalDay').hasError('required') && registerForm.get('finalDay').touched"
                                        class="text-danger">Este campo es requerido</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-7">
                <div class="row">
                    <div class="col-md-12">
                        <div class="row">
                            <label class="ml-3" for="hourInitial"><i class="fas fa-clock"></i> Horario de
                                atención:</label>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <input formControlName="initialHour" type="time" class="form-control"
                                        id="hourInitial">
                                    <small
                                        *ngIf="registerForm.get('initialHour').hasError('required') && registerForm.get('initialHour').touched"
                                        class="text-danger">Este campo es requerido</small>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <input formControlName="finalHour" type="time" class="form-control" id="hour">
                                    <small
                                        *ngIf="registerForm.get('finalHour').hasError('required') && registerForm.get('finalHour').touched"
                                        class="text-danger">Este campo es requerido</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <button [disabled]="registerForm.invalid" type="submit" class="btn btn-success w-100">Actualizar
            Información</button>
    </form>
</div>