import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { global } from './global';

@Injectable({
  providedIn: 'root'
})
export class PublicityService {

  public url: String;

  constructor(private _http: HttpClient) {
    this.url = global.url
  }

  publicity(publicity): Observable<any> {
    return this._http.post(`${this.url}publicity`, publicity); 
  }

  getPublicity(userid): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.get(`${this.url}publicity/${userid}`, { headers: headers });
  }

  getPublicities(): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.get(`${this.url}publicities`, { headers: headers });
  }
}
