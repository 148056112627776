import { Injectable } from '@angular/core';
import Pusher from 'pusher-js';

@Injectable({
  providedIn: 'root'
})
export class PusherService {

  pusher: any

  constructor() {
    this.pusher = new Pusher(
      '875d4a438d54ef18fefb',
      {
        cluster: 'us2'
      }
    );
  }

  subScribeToChannel(channelName: String, events: String[], cb: Function) {
    var channel = this.pusher.subscribe(channelName);
    events.forEach( event => {
      channel.bind(event, function(data) {
        cb(data)
      });
    })
  }
}
