<nav class="navbar fixed-top navbar-expand-lg navbar-dark bg-dark">
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup"
    aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
    <div *ngIf="_authService.loggedIn() && !_authService.isAdmin()" class="navbar-nav">
      <a class="nav-link" [routerLink]="['/tableMedical', idDoctor]" [routerLinkActive]="['active']">Agenda</a>
      <a class="nav-link" [routerLink]="['/publicity']" [routerLinkActive]="['active']">Publicidad</a>
    </div>

    <div *ngIf="_authService.loggedIn() && _authService.isAdmin()" class="navbar-nav">
      <a class="nav-link" [routerLink]="['/adminUsers']" [routerLinkActive]="['active']">Usuarios</a>
      <a class="nav-link" [routerLink]="['/adminBranch']" [routerLinkActive]="['active']">Ramas Médicas</a>
      <a class="nav-link" [routerLink]="['/adminSpeciality']" [routerLinkActive]="['active']">Especialidades</a>
    </div>

    <div *ngIf="!_authService.loggedIn()" class="navbar-nav">
      <a class="nav-link" [routerLink]="['/home']" [routerLinkActive]="['active']">Inicio</a>
    </div>
     

  </div>

  

  <div *ngIf="!_authService.loggedIn(); else notShow" class="ml-auto">
    <button class="btn btn btn-outline-info my-2 my-sm-0 mr-2 " data-toggle="modal" data-target="#login">Inicia Sesión</button>
    <button class="btn btn-outline-success my-2 my-sm-0" (click)="handdle()">Registrate</button>
  </div>
  <ng-template #notShow >
    <div class="ml-auto d-flex">
      <div class="btn-group">
        <button class="btn btn-dark" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <i class="font-notification far fa-bell"></i>
        <span class="badge badge-danger">{{ totalNotifications }}</span>
        </button>
        <div [ngClass]="{'dropdown-menu': true, 'box-notifications': notifications.length > 0}" aria-labelledby="dropdownMenuButton">
          <div *ngIf="notifications.length > 0">
            <div 
            *ngFor="let notification of notifications" 
            class="dropdown-item notifications-box"
            (click)="selectedNotification(notification._id, notification.doctor)">
              <strong >{{ notification.text }}</strong>
              <span>
                {{ notification.date | amLocale: 'es' | amTimeAgo }}
                <i *ngIf="notification.read" class="text-success">
                  <svg width="2em" height="2em" viewBox="0 0 16 16" class="bi bi-check-all" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M8.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L2.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093L8.95 4.992a.252.252 0 0 1 .02-.022zm-.92 5.14l.92.92a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 1 0-1.091-1.028L9.477 9.417l-.485-.486-.943 1.179z"/>
                  </svg>
                </i>
              </span>
              <hr>
            </div>
          </div>
          <div *ngIf="notifications.length == 0" class="dropdown-item">
            <strong>No hay notificaciones para mostrar.</strong>
            <hr>
          </div>
        </div>
      </div>

      
      <div class="btn-group dropleft ml-3 mr-3" >
        <img class="avatar" [src]="avatar" height="40vh" width="40vh"  data-toggle="dropdown"/> 
        <div class="dropdown-menu">
          <a class="dropdown-item" (click)="config()">Configuración</a>
          <a class="dropdown-item" (click)="_authService.logout()">Cerrar sesion</a>
        </div>
      </div>
    </div>
  </ng-template>

</nav>

<app-login></app-login>

<router-outlet></router-outlet> 
