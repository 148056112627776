import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin-modal-users',
  templateUrl: './admin-modal-users.component.html',
  styleUrls: ['./admin-modal-users.component.css']
})
export class AdminModalUsersComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
