import { OnInit, Component, ViewChild } from '@angular/core';
import { PaymentService } from "../../services/payment.service";
import { Router } from "@angular/router";
import { StripeService, StripeCardComponent } from 'ngx-stripe';
import {
  StripeCardElementOptions,
  StripeElementsOptions
} from '@stripe/stripe-js';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-payments', 
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.css']
})
export class PaymentsComponent implements OnInit {
  @ViewChild(StripeCardComponent) card: StripeCardComponent;

  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        fontWeight: '300',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#4B4949'
        }
      }
    }
  };

  elementsOptions: StripeElementsOptions = {
    locale: 'es'
  };

  url: String = 'url(../../assets/images/medicos2.jpg)';
  transactionError: boolean = false;
  loadigPayment: boolean = false;
  message: String;

  constructor(
    private PaymentService: PaymentService,
    private _route: Router,
    private stripeService: StripeService,
    private _userService: UserService, 
  ) { }


  ngOnInit() {

  }




  onClick() {

    this.loadigPayment = true;

    this.stripeService.createPaymentMethod({
      type: 'card',
      card: this.card.element
    }).subscribe(
      result => {
        if (result.paymentMethod) {
          this.PaymentService.charge(result.paymentMethod.id).subscribe(
            response => {
              var user = this._userService.getLoggedUser()._id;
              if (response.subscriptionObject._id) {
                this._route.navigate(['/tableMedical', user])
              }
              this.transactionError = false;
            },
            error => {
              this.message = 'Error en la transacción, verifique el estado de su tarjeta'
              this.loadigPayment = false
              this.transactionError = true;

            }
          )
        }

        if (result.error) {
          this.message = result.error.message
          this.loadigPayment = false
          this.transactionError = true;
        }

      },
      error => {
        this.message = 'Metodo de pago no valido'
        this.loadigPayment = false
        this.transactionError = true;

      }
    )








  }







}
