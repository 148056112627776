import { PublicityService } from './../../services/publicity.service';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { StripeService, StripeCardComponent } from 'ngx-stripe';
import {
  StripeCardElementOptions,
  StripeElementsOptions
} from '@stripe/stripe-js';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-publicity',
  templateUrl: './publicity.component.html',
  styleUrls: ['./publicity.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class PublicityComponent implements OnInit {
  @ViewChild(StripeCardComponent) card: StripeCardComponent;

  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        fontWeight: '300',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#4B4949'
        }
      }
    }
  };

  elementsOptions: StripeElementsOptions = {
    locale: 'es'
  };
  transactionError: boolean = false;
  loadigPayment: boolean = false;
  message: String;
  files: File[] = [];
  file: any;
  user: any;
  publicity:any = [];

  constructor(
    private stripeService: StripeService,
    private _userService: UserService,
    private _publicityService: PublicityService
  ) { }

  ngOnInit(): void {
    this.getImages();
  }

  onClick() {
    
    this.loadigPayment = true;

    this.stripeService.createPaymentMethod({
      type: 'card',
      card: this.card.element
    }).subscribe(
      result => {
        if (result.paymentMethod) {
          let paymentId = result.paymentMethod.id;
          let userId = this._userService.getLoggedUser()._id;
          let image = this.files[0];
 
          let formData = new FormData();
          formData.append('paymentId', paymentId);
          formData.append('userId', userId);
          formData.append('image', image);


          this._publicityService.publicity(formData).subscribe(
            res => {
              this.files = [];
              this.loadigPayment = false;
              this.getImages();


            },
            error => {
              this.message = error
              this.loadigPayment = false
              this.transactionError = true;
            }
          )

        }

        if (result.error) {
          this.message = result.error.message
          this.loadigPayment = false
          this.transactionError = true;
        }

      },
      error => {
        this.message = 'Metodo de pago no valido'
        this.loadigPayment = false
        this.transactionError = true;

      }   
    )
  }

  onSelect(event) {
    this.files[0] = event.addedFiles[0];

  }

  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
  }

  getImages(){
      this.user = this._userService.getLoggedUser()._id;

      this._publicityService.getPublicity(this.user).subscribe(
        res =>{
          this.publicity = res.publicity; 
          
        },
        error => {
          console.log(error);
             
        }
      )
  } 



}
