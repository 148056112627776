import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { global } from './global';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  public url: String;

  constructor(private _http: HttpClient) { 
    this.url = global.url
  }

  notifications(idDoctor, limit): Observable<any>{
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.get(`${this.url}notifications/${idDoctor}/${limit}`, { headers: headers });
  }

  updateNotification(idNotification, idDoctor): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this._http.put(`${this.url}notification/${idNotification}/${idDoctor}`, { headers: headers });
}
}
