import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-map-location',
  templateUrl: './map-location.component.html',
  styleUrls: ['./map-location.component.css']
})
export class MapLocationComponent implements OnInit {
  @Input() lat:number;
  @Input() lng:number;
  
  zoom = 15;

  constructor() { }

  ngOnInit(): void {
  }

}
