import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { PaymentService } from '../services/payment.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PaymentGuard implements CanActivate {
  constructor(
    private _router: Router,
    private _paymentService: PaymentService
  ) {

  }
  canActivate(): Observable<boolean> {
    return this._paymentService.confirmSuscription().pipe(
      map(res => {
        
        if (res.flag) {
          return true;
        }
        this._router.navigate(['/payment'])
        return false;
      })
    )


  }






}
