<div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
    <div class="carousel-inner">
        <div *ngFor="let publicity of publicities" [class]="publicity.class">
            <img [src]="publicity.image" height="400px" class="d-block w-100" alt="..."
                (click)="navigateDoctor(publicity.user)">
        </div>
    </div>
    <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
    </a>
</div>
<div class="container">

    <div class="row mt-2 pt-5 mb-5 pb-5">
        <div class="col-md-12">
            <div class="row d-flex justify-content-center">
                <div *ngFor="let branch of branches" class=" col-md-4 text-center" (click)="open()">
                    <app-medical-branches [branch]="branch"></app-medical-branches>
                </div>
            </div>


        </div>
    </div>
    <hr class="featurette-divider">
    <div class="row">
        <div class="col-md-12">

            <div class="row featurette">
                <div class="col-md-7 col align-self-center">
                    <h2 class="featurette-heading text-muted">¿Cómo encontrar al especialista más cercano y agendar una cita en
                        Encuentratumédico.com?
                    </h2>
                    <p class="lead">Selecciona la especialidad que deseas
                    </p>
                </div>
                <div class="col-md-5">
                    <img class="featurette-image img-fluid mx-auto"
                        src="../../../../assets/images/Imagen1.jpg"
                        alt="Generic placeholder image">
                </div>
            </div>

            <hr class="featurette-divider">

            <div class="row featurette">
                <div class="col-md-7 order-md-2 col align-self-center">
                    <h2 class="featurette-heading text-muted">O bien, puedes hacer utilizar el buscador para seleccionar el perfil
                        de acuerdo a tu ubicación.</h2>
                </div>
                <div class="col-md-5 order-md-1">
                    <img class="featurette-image img-fluid mx-auto"
                        src="../../../../assets/images/Imagen2.jpg"
                        alt="Generic placeholder image">
                </div>
            </div>

            <hr class="featurette-divider">

            <div class="row featurette">
                <div class="col-md-7 col align-self-center">
                    <h2 class="featurette-heading text-muted">Elige al especialista de tu preferencia </h2>
                </div>
                <div class="col-md-5">
                    <img class="featurette-image img-fluid mx-auto"
                        src="../../../../assets/images/Imagen3.jpg"
                        alt="Generic placeholder image">
                </div>
            </div>

            <hr class="featurette-divider">
            <div class="row featurette">
                <div class="col-md-7 order-md-2 col align-self-center">
                    <h2 class="featurette-heading text-muted">Revisa la ubicación de tu especialista, así como sus días y horarios
                        de atención disponibles.
                    </h2>
                </div>
                <div class="col-md-5 order-md-1">
                    <img class="featurette-image img-fluid mx-auto"
                        src="../../../../assets/images/Imagen4.jpg"
                        alt="Generic placeholder image">
                </div>
            </div>

            <hr class="featurette-divider">
            <div class="row featurette">
                <div class="col-md-7 col align-self-center">
                    <h2 class="featurette-heading text-muted">Elige el día y la hora de la cita de tu preferencia, de acuerdo a la disponibilidad del servicio.  
                    </h2>
                </div>
                <div class="col-md-5">
                    <img class="featurette-image img-fluid mx-auto"
                        src="../../../../assets/images/Imagen5.jpg"
                        alt="Generic placeholder image">
                </div>
            </div>

            <hr class="featurette-divider">

            <div class="row featurette">
                <div class="col-md-7 order-md-2 col align-self-center">
                    <h2 class="featurette-heading text-muted">Para asegurar tu cita, es necesario que agregues los datos de autenticidad (Nombre, edad, teléfono, Email y motivo de consulta). 

                        Por último, da click en «Agendar cita». 
                        
                    </h2>
                </div>
                <div class="col-md-5 order-md-1">
                    <img class="featurette-image img-fluid mx-auto"
                        src="../../../../assets/images/Imagen6.jpg"
                        alt="Generic placeholder image">
                </div>
            </div>

            <hr class="featurette-divider">
        </div>
    </div>
</div>
<footer class=" mt-md-5 pt-md-4 border-top bg-dark text-white pr-0">
    <div class="row mb-4 pr-0 w-100 mt-5">
        <div class="col-md-12 d-flex justify-content-center pr-0 ">
            <a style="color: white;" class="mx-auto mx-md-5" href="https://www.facebook.com/Encuentra-tu-m%C3%A9dico-106747068428892" target="_blank"><i
                    class="fab fa-facebook-square"></i></a>
            <a style="color: white;" class="mx-auto mx-md-5"href="https://www.instagram.com/encuentra.tu.medico/" target="_blank"><i
                    class="fab fa-instagram"></i></a>
            <a style="color: white;" class="mx-auto mx-md-5 "href="https://twitter.com/?lang=es" target="_blank"><i class="fab fa-twitter"></i></a>
        </div>
    </div>
    <div class="row pr-0 w-100  text-center">
        <div class="col-md-3 d-flex justify-content-center">
            <img class="mb-2 w-50" src="../../../../assets/images/logo.png" alt="">

        </div>
        <div class="col-md-3  text-center">
            <h5>Telefonos</h5>
            <ul class="list-unstyled text-small">
                <li><a class="text-muted">618-361-10-76 </a></li>
            </ul>
        </div>
        <div class="col-md-3 text-center">
            <h5>Correos</h5>
            <ul class="list-unstyled text-small">
                <li><a class="text-muted" href="#">ventas@encuentratumedico.com.mx</a></li>
                <li><a class="text-muted" href="#">etumedico@gmail.com</a></li>
            </ul>
        </div>
        <div class="col-md-3 pr-0  text-center">
            <h5>Acerca de Nosotros</h5>
            <ul class="list-unstyled text-small">
                <li><a class="text-muted" target="_blank"
                        href="https://drive.google.com/file/d/1-GDqZB2Vi4J6wmeFKAFYwJ4PI3ITnaz9/view?usp=sharing">Terminos
                        y condiciones</a></li>
                <li><a class="text-muted" target="_blank"
                        href="https://drive.google.com/file/d/14jX6yvoBMkWBicz66mpuemsdQ_Twbn0j/view?usp=sharing">Aviso
                        de privacidad</a></li>
            </ul>
        </div>
    </div>
</footer>