<div class="modal fade" id="branchModal" tabindex="-1" aria-hidden="true" data-backdrop="static">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <div class="w-100 d-flex justify-content-center">
          <h5 class="modal-title dispaly-4" id="branchModal">Nuevo elemento</h5>
        </div>
        <button type="button" class="close ml-0" #closebutton (click)="reset()" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">

        <form [formGroup]="branchForm" (ngSubmit)="onSubmit(branchForm.value)">

          <div class="form-group">
            <label for="nameBranch"><i class="fas fa-code-branch"></i> Nombre de la rama médica:</label>
            <input type="text" formControlName="name" name="nameBranch" [class]="classValidName" id="nameBranch"
              placeholder="Ingrese la rama médica" (blur)="validClassForm('name')" (input)="onTouchClass('name')" (change)="validClassForm('name')">
            <small *ngIf="branchForm.get('name').hasError('required') && branchForm.get('name').touched" class="text-danger">Este campo es requerido</small>
          </div>

          <div class="form-group">
            <label for="descriptionBranch"><i class="fas fa-comment-alt"></i> Descripcion de la rama médica:</label>
            <textarea [class]="classValidDescription" formControlName="description" name="description" id="descriptionBranch"
              rows="3" (input)="onTouchClass('description')" (blur)="validClassForm('description')" (change)="validClassForm('description')"></textarea>
              <small *ngIf="branchForm.get('description').hasError('required') && branchForm.get('description').touched" class="text-danger">Este campo es requerido</small>
          </div>

          <div class="row">
            <div class="col-md-12">
              <label for="branchFile"><i class="fas fa-file-image"></i> Imagen de la rama médica:</label>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-md-12">
              <div class="custom-file">
                <input type="file" class="custom-file-input " id="branchFile" (blur)="validClassForm('image')" (change)="selectImage($event)">
                <label [class]="classValidImage" for="inputGroupFile01">
                  <span *ngIf="branchForm.value.image != null">{{branchForm.value.image.name}}</span>
                </label>
              </div>
              <small *ngIf="branchForm.get('image').hasError('required') && branchForm.get('image').touched" class="text-danger">Este campo es requerido</small>
            </div>
          </div>

          <button [disabled]="branchForm.invalid" type="submit" class="btn btn-success w-100">Registrar</button>
        </form>

      </div>
    </div>
  </div>
</div>
