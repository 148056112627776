import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { UserService } from "../../../services/user.service";
import { Router } from "@angular/router";
import { AppComponent } from './../../../app.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  public signinForm: FormGroup;
  public idUser: string;
  public alert = {
    flag: false,
    message: ''
  }

  constructor(
    private _authService: UserService,
    private _builder: FormBuilder,
    private _router: Router,
    private appComponent: AppComponent
  ) {
    this.signinForm = this._builder.group({
      email: ['', Validators.compose([Validators.required, Validators.email])],
      password: ['', Validators.required]
    })
  }

  ngOnInit(): void {}

  onSubmit(form) {
    var params = {
      email: form.email,
      password: form.password
    }
    this._authService.signIn(params).subscribe(
      res => {
        this.idUser = res.user._id;
        this.appComponent.getLoggedUser();
        this.appComponent.getNotificationsByDoctor();
        this.closebutton.nativeElement.click();
        var userLog = this._authService.getLoggedUser();

        if(userLog.role == 'admin') {
          this._router.navigateByUrl('adminUsers');
        }else{
          this._router.navigate(['tableMedical', this.idUser])
        }
      },
      error => {
        try {
          switch (error.status) {
            case 404:
              this.alert.flag = true;
              this.alert.message = 'No existe el usuario';
              break;
            case 401:
              this.alert.flag = true;
              this.alert.message = 'Contraseña incorrecta'; 
              break;
            default:
              break;
          }
        } catch (error) {
          console.log(error);
        }
      }
    )

  }

  reset() {
    this.alert.flag = false;
    this.alert.message = '';
    this.signinForm.reset();
  }

}
