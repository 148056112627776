import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FilterSubjectService {
  // Observable string sources
  private missionConfirmedSource = new BehaviorSubject<string>("");

  // Observable string streams
  missionConfirmed$ = this.missionConfirmedSource.asObservable();

  // Service message commands
  

  sendFilterValue(search: string) {
    this.missionConfirmedSource.next(search);
  }
}
