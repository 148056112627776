<div class="container-fluid position-absolute"
    [ngStyle]="{'background-image':url,'height': '100%','width': '100%', 'background-color': 'rgba(0,0,0,0.6)', 'filter':'brightness(0.5)'}">

</div>
<div class="container h-100">
    <div class="row h-100">
        <div class="col-md-12 h-100 align-self-center">

            <div class="row h-50 mb-5">
                <div class="col-md-4 mx-auto">
                    <div class="text-center ">
                        <div>

                            <p><span class="text-white display-1">99</span><span class="text-white textLittle">MX /
                                    Mes</span></p>
                            <p class="text-white mb-5 pb-5 textBig">Suscribete y obten todos los beneficios de nuestra
                                aplicación</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row h-50">
                <div class="col-md-8 mx-auto">
                    <div class="card transparentCard shadow mb-5 bg-white rounded">
                        <div class="card-header text-center">
                            Suscribete ahora
                        </div>
                        <div class="card-body">
                            <div *ngIf="transactionError" class="alert alert-danger text-center" role="alert">
                                {{message}}
                            </div>
                            <div>
                                <div class="row mb-2">
                                    <div class="ml-3">Tarjeta de crédito/débito: </div>
                                    <div class="mr-3 ml-auto"> <img src="../../../assets/creditCards.png" alt=""
                                            height="25px"></div>
                                </div>

                                <div class="credit-card form-control inputCard">
                                    <div>
                                        <ngx-stripe-card [options]="cardOptions" [elementsOptions]="elementsOptions">
                                        </ngx-stripe-card>
                                    </div>
                                </div>
                            </div>

                            <button *ngIf="loadigPayment===false" type="button" class="btn btn-info mt-4 h-50 w-100"
                                (click)="onClick()">Suscribirse</button>
                            <button *ngIf="loadigPayment" type="button" class="btn btn-info mt-4 h-20 w-100" disabled>
                                <div class="spinner-border text-secondary" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>



        </div>
    </div>

</div>