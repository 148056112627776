import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { global } from './global';
import { Router } from "@angular/router";
import * as CryptoJS from 'crypto-js';  

@Injectable({
  providedIn: 'root'
})
export class UserService {

  public url: String;
  private token: String;
  private SECRET_KEY: String = '159olgtdSFD#"%125&/%(24651';


  constructor(
    private _http: HttpClient,
    private _route: Router
    ) {
    this.url = global.url;
  }

  signUp(user): Observable<any> {
    const headers = new HttpHeaders().set(
      'Content-Type', 'application/json'
    );
    return this._http.post(`${this.url}signup`, user, { headers: headers }).pipe(
      tap(
        (res)=>{
          this.saveToken(res);
        }
      )
    );
  }

  signIn(user): Observable<any> {
    const headers = new HttpHeaders().set(
      'Content-Type', 'application/json'
    );
    return this._http.post(`${this.url}signin`, user, { headers: headers }).pipe(
      tap(
        (res)=>{
          this.saveToken(res);
        }
      )
    );
  }

  getUsersbyBranchAndSpeciality(branch, speciality): Observable<any> {
    let headers = new HttpHeaders().set(
      'Content-Type', 'application/json'
      );
    return this._http.get(`${this.url}users/${branch}/${speciality}`, { headers: headers });
  }

  getUserById(user): Observable<any> {
    let headers = new HttpHeaders().set(
      'Content-Type', 'application/json'
      );
    return this._http.get(`${this.url}user/${user}`, { headers: headers })
  }

  getUsers(): Observable<any> {
    let headers = new HttpHeaders().set(
      'Content-Type', 'application/json'
      );
    return this._http.get(`${this.url}users`, { headers: headers })
  }

  deleteUser(user): Observable<any> {
    let headers = new HttpHeaders().set(
      'Content-Type', 'application/json'
      );
    return this._http.delete(`${this.url}user/${user._id}`, { headers: headers })
  }

  updateUser(user, id): Observable<any> {
    return this._http.put(`${this.url}user/${id}`,user) 
  }

  logout(){
    this.token = '';
    localStorage.removeItem("ACCESS_TOKEN");
    localStorage.removeItem("DATA");
    this._route.navigate(['/home'])
  }



  private saveToken(data){
    
    let token = data.token;
    
    let user = {
      '_id': data.user._id,
      'name': data.user.name,
      'avatar': data.user.avatar,
      'role': data.user.role,
    }
    let userCrypto = CryptoJS.AES.encrypt(JSON.stringify(user), this.SECRET_KEY).toString();
    localStorage.setItem("DATA", userCrypto);
    localStorage.setItem("ACCESS_TOKEN", token);
    this.token = token;
  }

  getLoggedUser(){
    let data = localStorage.getItem("DATA");
    let userDecrypted;
    if(data !== null){
      let bytes  = CryptoJS.AES.decrypt(data, this.SECRET_KEY);
      userDecrypted = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    }
    return userDecrypted;
  }

  isAdmin(){
    let data = localStorage.getItem("DATA");
    let userDecrypted;
    if(data !== null){
      let bytes  = CryptoJS.AES.decrypt(data, this.SECRET_KEY);
      userDecrypted = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    }
    return !!(userDecrypted.role === "admin")
  } 
  
  getToken(){
    this.token = "";
    if(!this.token){
      this.token = localStorage.getItem("ACCESS_TOKEN");
    }
    return this.token;
  }



  loggedIn(){
    return !!localStorage.getItem("ACCESS_TOKEN");
  }
}
