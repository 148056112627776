import { Component, OnInit } from '@angular/core';
import { SpecialityService } from '../../services/speciality.service'
import { ToastrService } from 'ngx-toastr';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
export interface branchData {
  name: string;
  branch: string;
}

@Component({
  selector: 'app-admin-speciality',
  templateUrl: './admin-speciality.component.html',
  styleUrls: ['./admin-speciality.component.css']
})
export class AdminSpecialityComponent implements OnInit {
  dataResult: any[] = [];
  displayedColumns: string[] = ['name', 'branch','action'];
  dataSource: MatTableDataSource<branchData>;

  constructor(private _specialityService: SpecialityService) {

  }

  ngOnInit(): void {
    this.getData();
  }

  getData() {
    this._specialityService.getSpecialities().subscribe(
      response => {
         
        this.dataResult = response.specialties;
        this.dataResult = this.dataResult.filter(data => data.branch !== null)
        this.dataSource = new MatTableDataSource(this.dataResult);
      },
      err => {
        console.log(err);
        
      }
    )
  }

  deleteData(data){
    this._specialityService.deleteSpeciality(data).subscribe(
      response => {
         
        this.getData();
      },
      err => {
        console.log(err);
        
      }
    )
  }
   
  refreshTable(){
    this.getData();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
