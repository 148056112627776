import { Component, OnInit, ViewChild } from '@angular/core';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { MedicalBranchService } from '../../../services/medical-branch.service';
import { SpecialityService } from '../../../services/speciality.service';
import { UserService } from '../../../services/user.service';
import { Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  @ViewChild("placesRef") placesRef: GooglePlaceDirective;
  public registerForm: FormGroup;
  public lat: any;
  public lng: any; 
  public address: any;
  public branches = []; 
  public specialities = [];

  constructor(
    private _builder: FormBuilder,
    private _medicalBranch: MedicalBranchService,
    private _speciality: SpecialityService,
    private _user: UserService,
    private _router: Router,
    private appComponent: AppComponent
  ) {
    this.registerForm = this._builder.group({
      name: ['', Validators.required],
      branch: ['', Validators.required],
      speciality: ['', Validators.required],
      hospital: ['', Validators.required],
      address: ['', Validators.required],
      initialDay: ['Lunes', Validators.required],
      finalDay: ['Domingo', Validators.required],
      initialHour: ['', Validators.required],
      finalHour: ['', Validators.required],
      email: ['', Validators.compose([Validators.required, Validators.email])],
      password: ['', Validators.required]
    })
  }

  ngOnInit() {
    this.getBranches();
  }

  getBranches() {
    this._medicalBranch.getBranches().subscribe(
      response => {
        this.branches = response.branches;
      },
      error => {
        console.log(error);
      }
    )
  }

  getSpecialities(branch) {
    this.specialities = [];

    this._speciality.getSpecialitiesByBranch(branch).subscribe(
      response => {
        this.specialities = response.specialities;

      },
      error => {
        console.log(error);

      }
    )


  }

  handleAddressChange(address: Address) {
    this.address = address.formatted_address;
    this.lat = address.geometry.location.lat();
    this.lng = address.geometry.location.lng();
  }

  onSubmit(form) {
    var params = {
      name: form.name,
      branch: form.branch,
      speciality: form.speciality,
      hospital: form.hospital,
      address: this.address,
      lat: this.lat,
      lng: this.lng,
      initialDay: form.initialDay,
      finalDay: form.finalDay,
      initialHour: form.initialHour,
      finalHour: form.finalHour,
      email: form.email,
      password: form.password,
    }
    this._user.signUp(params).subscribe(
      response => {
        this.appComponent.getLoggedUser();
        this.appComponent.getNotificationsByDoctor();
        var userLog = this._user.getLoggedUser();

        if(userLog.role == 'admin') {
          this._router.navigateByUrl('adminUsers');
        }else{
          this._router.navigateByUrl('/payment');
        }

      },
      error => {
        console.log(error);

      }
    )


  }


}
