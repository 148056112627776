import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-medical-configuration',
  templateUrl: './medical-configuration.component.html',
  styleUrls: ['./medical-configuration.component.css']
})
export class MedicalConfigurationComponent implements OnInit {
  @ViewChild("placesRef") placesRef: GooglePlaceDirective;
  public registerForm: FormGroup;
  public lat: any;
  public lng: any;
  public address: any;
  public user: string;
  public avatar: string;
  public files: File[] = [];

  constructor(
    private _user: UserService,
    private _router: Router,
    private _builder: FormBuilder,
  ) {
    this.registerForm = this._builder.group({
      name: ['', Validators.required],
      hospital: ['', Validators.required],
      address: ['', Validators.required],
      initialDay: ['Lunes', Validators.required],
      finalDay: ['Domingo', Validators.required],
      initialHour: ['', Validators.required],
      finalHour: ['', Validators.required]
    })
  }

  ngOnInit(): void {
    this.getUser();
  }

  handleAddressChange(address: Address) {
    this.address = address.formatted_address;
    this.lat = address.geometry.location.lat();
    this.lng = address.geometry.location.lng();
  }

  onSelect(event) {
    this.files[0] = event.addedFiles[0];

  }

  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
  }

  getUser() {
    this.user = this._user.getLoggedUser()._id;
    

    this._user.getUserById(this.user).subscribe(
      res => {

        if (!res.user) return;
        if (res.user.length === 0) return;


        this.setUser(res.user[0]);

      },
      error => {

      }
    )
  }


  setUser(user) {
    console.log(user);

    this.registerForm.controls['name'].setValue(user.name);
    this.registerForm.controls['hospital'].setValue(user.hospital);
    this.registerForm.controls['address'].setValue(user.address);
    this.registerForm.controls['initialDay'].setValue(user.initialDay);
    this.registerForm.controls['finalDay'].setValue(user.finalDay);
    this.registerForm.controls['initialHour'].setValue(user.initialHour);
    this.registerForm.controls['finalHour'].setValue(user.finalHour);
    this.avatar = user.avatar;
    this.lat = user.lat;
    this.lng = user.lng;
    this.address = user.address;

  }




  onSubmit(form) {


    let name = form.name;
    let hospital = form.hospital;
    let address = this.address;
    let lat = this.lat;
    let lng = this.lng;
    let initialDay = form.initialDay;
    let finalDay = form.finalDay;
    let initialHour = form.initialHour;
    let finalHour = form.finalHour

    const formData = new FormData();
    formData.append('name', name);
    formData.append('hospital', hospital);
    formData.append('address', address);
    formData.append('lat', lat);
    formData.append('lng', lng);
    formData.append('initialDay', initialDay);
    formData.append('finalDay', finalDay);
    formData.append('initialHour', initialHour);
    formData.append('finalHour', finalHour);
    if (this.files.length > 0) {
      let image = this.files[0];
      formData.append('image', image);
    }
    this._user.updateUser(formData, this.user).subscribe(
      response => {
        this._router.navigate(['/tableMedical', this.user]);
      },
      error => {
        console.log(error);

      }
    )


  }

}
