import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home/home.component';
import { SpecialityComponent } from './components/speciality/speciality/speciality.component';
import { ListComponent } from './components/speciality/list/list.component';
import { MedicalInformationComponent } from './components/speciality/medical-information/medical-information.component';
import { MedicalComponent } from './components/medical/medical/medical.component';
import { MedicalConfigurationComponent } from './components/medical-configuration/medical-configuration.component';
import { TableMedicalComponent } from './components/tableMedical/table-medical/table-medical.component';
import { RegisterComponent } from './components/login/register/register.component';
import { AdminBranchComponent } from './components/admin-branch/admin-branch.component';
import { AdminUsersComponent } from './components/admin-users/admin-users.component';
import { AdminSpecialityComponent } from './components/admin-speciality/admin-speciality.component';
import { PaymentsComponent } from "./components/payments/payments.component";
import { PublicityComponent } from './components/publicity/publicity.component';

//GUARDS
import { MedicalGuard } from "./guard/medical.guard";
import { PaymentGuard } from "./guard/payment.guard";
import { IsadminGuard } from "./guard/isadmin.guard";
import { SuccessPaymentGuard } from "./guard/success-payment.guard";

const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'register',
    component: RegisterComponent
  },
  {
    path: 'home',
    component: HomeComponent
  },
  {
    path: 'medical/:id',
    component: MedicalComponent,
    
  },
  {
    path: 'medicalConfiguration/:id',
    component: MedicalConfigurationComponent,
    canActivate: [MedicalGuard,PaymentGuard]  
  },
  {
    path: 'tableMedical/:idDoctor',
    component: TableMedicalComponent,
    canActivate: [MedicalGuard,PaymentGuard]
  },
  {
    path: 'adminBranch',
    component: AdminBranchComponent,
    canActivate: [MedicalGuard, IsadminGuard]
  },
  {
    path: 'adminSpeciality',
    component: AdminSpecialityComponent,
    canActivate: [MedicalGuard, IsadminGuard]
  },
  {
    path: 'adminUsers',
    component: AdminUsersComponent,
    canActivate: [MedicalGuard, IsadminGuard]
  },
  {
    path: 'speciality',
    component: SpecialityComponent,
    children: [
      { 
        path: ':idBranch',
        component: ListComponent,
      },
      {
        path: ':idBranch/:idSpeciality',
        component: MedicalInformationComponent,
      },
    ]
  },
  {
    path: 'payment',
    component: PaymentsComponent,
    canActivate: [MedicalGuard,SuccessPaymentGuard]
  },
  {
    path: 'publicity',
    component: PublicityComponent,
    canActivate: [MedicalGuard,PaymentGuard]  
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule] 
})
export class AppRoutingModule { }
