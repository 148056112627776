import { FilterSubjectService } from './../../../services/filter-subject.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SpecialityService } from '../../../services/speciality.service'

@Component({
  selector: 'app-speciality',
  templateUrl: './speciality.component.html',
  styleUrls: ['./speciality.component.css']
})
export class SpecialityComponent implements OnInit {
  public specialities: any = [];
  public branch: any = {};
  public url: string = ""
  constructor(
    private _route: ActivatedRoute,
    private _speciality: SpecialityService,
    private _filterService: FilterSubjectService
  ) {
    
   }

  ngOnInit(): void {
    this.getSpecialities();
  }

  getSpecialities() {
    this._route.children[0].params.subscribe((params) => {
      var idBranch = params.idBranch;
      this._speciality.getSpecialitiesByBranch(idBranch).subscribe(
        response => {
          this.specialities = response.specialities;
          if (this.specialities.length > 0) {
            this.branch = this.specialities[0].branch;
            this.url = `url(${this.branch.image})`;
          }

        },
        err => {
          console.log(err);
        })
    })

  }

  search(){
    
  }

  onSearchChange(event){

    var searchValue = event.target.value; 
    
    this._filterService.sendFilterValue(searchValue); 
  }

}
