import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { UserService } from 'src/app/services/user.service';

export interface userData {
  name: string;
  branch: string;
  speciality: string;
  hospital: string;
  address: string;
  email: string;
}

@Component({
  selector: 'app-admin-users',
  templateUrl: './admin-users.component.html',
  styleUrls: ['./admin-users.component.css']
})
export class AdminUsersComponent implements OnInit {
  dataResult: any[] = [];
  displayedColumns: string[] = ['name', 'branch','speciality', 'hospital', 'address', 'email', 'action'];
  dataSource: MatTableDataSource<userData>;

  constructor(private _userService: UserService) { }

  ngOnInit(): void {
    this.getData();
  }

  getData() {
    this._userService.getUsers().subscribe(
      response => {
         console.log(response)
        this.dataResult = response.users;
        this.dataResult = this.dataResult.filter(data => data.branch !== null && data.speciality !== null && data.role != "admin");
        this.dataSource = new MatTableDataSource(this.dataResult);
      },
      err => {
        console.log(err);
        
      }
    )
  }

  deleteData(data){
    this._userService.deleteUser(data).subscribe(
      response => {
          console.log(response);
          
        this.getData();
      },
      err => {
        console.log(err);
        
      }
    )
    
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

}
 