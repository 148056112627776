
<div class="container-fluid mt-4">
    <div class="row">
        <div class="col-md-12 mx-auto">
            <div class="row">
                <div class="col-md-12">
                    <div class="card shadow-sm mb-4 bg-white rounded">
                        <div class="card-body">
                            <div class="d-flex justify-content-center">
                                <span class="font-style">Usuarios <i class="fas fa-user-alt"></i></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12 mx-auto">
                    <div class="row">
                        <div class="col-md-4">
                            <!-- <mat-form-field>
                                <mat-label>buscar</mat-label>
                                <input matInput (keyup)="applyFilter($event)" placeholder="Realice su busqueda">
                            </mat-form-field> -->
                        </div>
                        <div class="col-md-2 ml-auto text-right">
                            <!-- <button type="button" class="btn btn-info rounded-circle" data-toggle="modal"
                                data-target="#branchModal">
                                <i class="fas fa-plus"></i>
                            </button> -->
                        </div>
                    </div>
                </div>


            </div>
            <div class="row">
                <div class="col-md-8 mx-auto">
                    <mat-form-field appearance="standard">
                        <mat-label>Filter</mat-label>
                        <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Picologia" #input>
                    </mat-form-field>

                    <div class="mat-elevation-z8">
                        <table mat-table [dataSource]="dataSource" matSort>



                            <!-- Name Column -->
                            <ng-container matColumnDef="name">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                                <td mat-cell *matCellDef="let row"> {{row.name}} </td>
                            </ng-container>

                            <!-- description Column -->
                            <ng-container matColumnDef="branch">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Rama Médica</th>
                                <td mat-cell *matCellDef="let row"> {{row.branch.name}} </td>
                            </ng-container>

                            <!-- description Column -->
                            <ng-container matColumnDef="speciality">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Especialidad</th>
                                <td mat-cell *matCellDef="let row"> {{row.speciality.name}} </td>
                            </ng-container>

                            <!-- description Column -->
                            <ng-container matColumnDef="hospital">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Hospital</th>
                                <td mat-cell *matCellDef="let row"> {{row.hospital}} </td>
                            </ng-container>

                            <!-- description Column -->
                            <ng-container matColumnDef="address">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Dirección</th>
                                <td mat-cell *matCellDef="let row"> {{row.address}} </td>
                            </ng-container>

                            <!-- description Column -->
                            <ng-container matColumnDef="email">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Email</th>
                                <td mat-cell *matCellDef="let row"> {{row.email}} </td>
                            </ng-container>


                            <ng-container matColumnDef="action">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Accion</th>
                                <td mat-cell *matCellDef="let row">
                                    <button type="button" class="btn btn-danger ml-md-2"><i
                                        (click)="deleteData(row)"  class="fas fa-trash-alt"></i></button>   
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                            <!-- Row shown when there is no matching data. -->
                            <tr class="mat-row" *matNoDataRow>
                                <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
                            </tr>
                        </table>

                        <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of users">
                        </mat-paginator>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
