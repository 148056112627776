export class Appointment{
    constructor(
        public name: String,
        public age: Number,
        public phone: String,
        public email: String,
        public date: String,   
        public description: String,
        public doctor: String   
    ){}
}