<app-admin-modal-branch (refreshTable)="refreshTable()"></app-admin-modal-branch>
<div class="container-fluid mt-4">
    <div class="row">
        <div class="col-md-11 mx-auto">
            <div class="row">
                <div class="col-md-12">
                    <div class="card shadow-sm mb-4 bg-white rounded">
                        <div class="card-body">
                            <div class="d-flex justify-content-center">
                                <span class="font-style">Ramas de la Salud <i class="fas fa-code-branch"></i></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-8 mx-auto">
                    <div class="row">
                        <div class="col-md-4">
                            <!-- <mat-form-field>
                                <mat-label>buscar</mat-label>
                                <input matInput (keyup)="applyFilter($event)" placeholder="Realice su busqueda">
                            </mat-form-field> -->
                        </div>
                        <div class="col-md-2 ml-auto text-right">
                            <button type="button" class="btn btn-info rounded-circle" data-toggle="modal"
                                data-target="#branchModal">
                                <i class="fas fa-plus"></i>
                            </button>
                        </div>
                    </div>
                </div>


            </div>
            <div class="row">
                <div class="col-md-8 mx-auto">


                    <!-- <table class="table shadow p-3 mb-5 bg-white rounded">
                        <thead class="thead-dark">
                            <tr>
                                <th class="text-center" scope="col">Nombre</th>
                                <th class="text-center" scope="col">Descripción</th>
                                <th class="text-center" scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let data of dataResult">
                                <td class="text-center">{{data.name}}</td>
                                <td class="text-center">{{data.description}}</td>
                                <td class="text-center">
                                    <button type="button" class="btn btn-success" (click)="editData(data)"><i
                                            class="fas fa-edit"></i></button>
                                    <button type="button" class="btn btn-danger ml-md-2" (click)="deleteData(data)"><i
                                            class="fas fa-trash-alt"></i></button>
                                </td>
                            </tr>
                        </tbody>
                    </table>  -->

                    <mat-form-field appearance="standard">
                        <mat-label>Filter</mat-label>
                        <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Picologia" #input>
                    </mat-form-field>

                    <div class="mat-elevation-z8">
                        <table mat-table [dataSource]="dataSource" matSort>



                            <!-- Name Column -->
                            <ng-container matColumnDef="name">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                                <td mat-cell *matCellDef="let row"> {{row.name}} </td>
                            </ng-container>

                            <!-- description Column -->
                            <ng-container matColumnDef="description">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Description</th>
                                <td mat-cell *matCellDef="let row"> {{row.description}} </td>
                            </ng-container>


                            <ng-container matColumnDef="action">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Accion</th>
                                <td mat-cell *matCellDef="let row">
                                    <button type="button" class="btn btn-danger ml-md-2"><i
                                        (click)="deleteData(row)"  class="fas fa-trash-alt"></i></button>   
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                            <!-- Row shown when there is no matching data. -->
                            <tr class="mat-row" *matNoDataRow>
                                <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
                            </tr>
                        </table>

                        <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of users">
                        </mat-paginator>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>