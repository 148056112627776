import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../../../services/user.service'
import { AppointmentService } from '../../../services/appointment.service'
import { Appointment } from '../../../models/appointment';
import * as moment from 'moment';

@Component({
  selector: 'app-medical',
  templateUrl: './medical.component.html',
  styleUrls: ['./medical.component.css'],
  providers: [UserService, AppointmentService]
})
export class MedicalComponent implements OnInit {
  public appointmentForm: FormGroup;
  public appointment: Appointment;
  public idUser: string;
  public date: string;
  public dateToSave: string;
  public alertMessage: string;
  public sendUser: Object;
  public user: any = {
    name: '',
    speciality: {name:''},
    hospital: '',
    initialDay: '',
    finalDay: '',
    initialHour: '',
    finalHour: '',
    lat: null,
    lng: null
  };
  public alert = {
    flag: false,
    message: ''
  }

  constructor(
    private _builder: FormBuilder,
    private _route: ActivatedRoute,
    private _user: UserService,
    private _appointment: AppointmentService,
  ){
    this.appointmentForm = this._builder.group({
      name: ['', Validators.required],
      age: ['', Validators.required],
      email: [
        '',
        Validators.compose(
          [Validators.required,
            Validators.email,
            Validators.pattern(
              '^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'
            )
          ]
        )
      ],
      phone: ['', Validators.required],
      date: ['', Validators.required],
      description: ['', Validators.required]
    });
    this.appointment = new Appointment('', 0, '', '', '', '', '');
  }

  get f() { return this.appointmentForm.controls; }

  ngOnInit(): void {
    this.getUser();
  }

  getUser() {
    this._route.params.subscribe((params) => {
      this.idUser = params.id;
      this._user.getUserById(this.idUser).subscribe(
        response =>{
          this.user = response.user[0];
          this.sendUser = response.user[0];
        },
        error => {
          console.log(error);
        }
      )
    })
  }

  getDateToChildren(e){
    let dateFormatLong = moment(e, 'YYYY-MM-DD HH:mm a').locale('es-mx').format('LL, h:mm a');
    let dateFormatShort = moment(e, 'YYYY-MM-DD HH:mm a').locale('es-mx').format('YYYY-MM-DD HH:mm a');
    this.dateToSave = dateFormatShort;
    this.date = dateFormatLong;
    this.appointment.date = this.date;
  }

  onSubmit(form){
    this.appointment = new Appointment(
      form.controls.name.value,
      form.controls.age.value,
      form.controls.phone.value,
      form.controls.email.value,
      this.dateToSave,
      form.controls.description.value,
      this.user._id
    );
    this._appointment.appointment(this.appointment).subscribe(
      response =>{
        switch(response.status){
          case 200:
            this.alert.flag = true;
            this.alert.message = 'Cita agendada con exito';
            form.reset();
            this.alertMessage = 'animated backInDown';
            setTimeout (() => {
              this.alert.flag = false;
            }, 3000);
            break;
          case 207:
            this.alert.flag = true;
            this.alert.message = response.message;
            this.alertMessage = 'animated backInDown';
            setTimeout (() => {
              this.alert.flag = false;
            }, 4000);
            break;
        }
      },
      err =>{
        console.log(err);
      }
    );
  }

}
