import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FilterSubjectService } from 'src/app/services/filter-subject.service';
import { SpecialityService } from '../../../services/speciality.service';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class ListComponent implements OnInit {

  public specialities: any = [];
  public specialitiesOrigin: any = [];
  public idBranch: any;
  constructor(
    private _route: ActivatedRoute, 
    private _speciality: SpecialityService,
    private _filterService: FilterSubjectService
  ) {
    this._filterService.missionConfirmed$.subscribe( filterValue =>{
      
      this.specialities = this.specialitiesOrigin.filter(speciality => speciality.name.toUpperCase().trim().includes(filterValue.toUpperCase().trim()))
      
    })
   }

  ngOnInit(): void {
    this.getSpecialities();
  }

  getSpecialities() {
    this._route.params.subscribe((params) =>{
      this.idBranch = params.idBranch;
      this._speciality.getSpecialitiesByBranch(this.idBranch).subscribe(
        response => {
          this.specialitiesOrigin = response.specialities;
          this.specialities = this.specialitiesOrigin;
        },
        err => {
          console.log(err);
        })
    })

  }

  

}
