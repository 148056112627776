import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../services/user.service';
import { Router } from '@angular/router';
import { PaymentService} from '../services/payment.service'

@Injectable({
  providedIn: 'root'
})
export class MedicalGuard implements CanActivate {

  constructor(
    private _authService: UserService,
    private _router: Router,
    private _paymentService: PaymentService
  ) {

  }

  canActivate(): boolean {
    


    if (this._authService.loggedIn()) {
      
      return true;
    }

    this._router.navigate(['/home']);
    return false

  }

}
