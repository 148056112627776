import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FilterSubjectService } from 'src/app/services/filter-subject.service';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-medical-information',
  templateUrl: './medical-information.component.html',
  styleUrls: ['./medical-information.component.css']
})
export class MedicalInformationComponent implements OnInit {
  public users: any = [];
  public usersOrigin: any = [];
  public idBranch: any;
  public idSpeciality: any;
  public idUser:String;

  constructor(
    private _routeNavigate: Router,
    private _route: ActivatedRoute,
    private _user: UserService,
    private _filterService: FilterSubjectService
  ) {
    this._filterService.missionConfirmed$.subscribe( filterValue =>{
      
      this.users = this.usersOrigin.filter(user => user.name.toUpperCase().trim().includes(filterValue.toUpperCase().trim()) || user.address.toUpperCase().trim().includes(filterValue.toUpperCase().trim()))
      
    })
   }

  ngOnInit(): void {
    this.getUsers();
  }

  getUsers() {
    this._route.params.subscribe((params) => {
      this.idBranch = params.idBranch;
      this.idSpeciality = params.idSpeciality;
      this._user.getUsersbyBranchAndSpeciality(
        this.idBranch, this.idSpeciality).subscribe(
          response => {
            console.log(response.users);
            
            this.usersOrigin = response.users;
            this.users = this.usersOrigin;
          },
          err => {
            console.log(err);
          }
        )


    })

  }

  handdle(user) {
    this.idUser = user._id;
    this._routeNavigate.navigate(['medical', this.idUser]);
  }

}
