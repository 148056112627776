import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { PaymentService } from '../services/payment.service';
import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root'
})
export class IsadminGuard implements CanActivate {
  constructor(
    private _authService: UserService,
    private _router: Router,
    private _paymentService: PaymentService
  ) {

  }

  canActivate(): boolean {
    
    var user = this._authService.getLoggedUser();

    if (user.role == 'admin') {
      
      return true;
    }

    this._router.navigate(['/home']);
    return false

  }

  
}
