<div class="container mb-5">
    <h2 class="pt-5 mt-2 text-center mb-4"><strong>Registrate</strong></h2>

    <form [formGroup]="registerForm" (ngSubmit)="onSubmit(registerForm.value)">
        <div class="form-group">
            <label for="name"><i class="fas fa-user"></i> Nombre:</label>
            <input type="text" formControlName="name" class="form-control" id="name"
                placeholder="Ingrese su nombre completo">
            <small *ngIf="registerForm.get('name').hasError('required') && registerForm.get('name').touched"
                class="text-danger">Este campo es requerido</small>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="form-group"> 
                    <label for="branchmedical"><i class="fas fa-code-branch"></i> Profesión:</label>
                    <select formControlName="branch" class="form-control" id="branchmedical"
                        (change)="getSpecialities(registerForm.value.branch)">
                        <option *ngFor="let branchSelect of branches" [value]="branchSelect._id">{{branchSelect.name}}
                        </option>

                    </select>
                    <small *ngIf="registerForm.get('branch').hasError('required') && registerForm.get('branch').touched"
                        class="text-danger">Este campo es requerido</small>
                </div>
            </div>

            <div class="col-md-6">
                <div class="form-group">
                    <label for="speciality"><i class="fas fa-graduation-cap"></i> Especialidad:</label>
                    <select formControlName="speciality" class="form-control" id="speciality">
                        <option *ngFor="let specialitySelect of specialities" [value]="specialitySelect._id">
                            {{specialitySelect.name}}</option>
                    </select>
                    <small
                        *ngIf="registerForm.get('speciality').hasError('required') && registerForm.get('speciality').touched"
                        class="text-danger">Este campo es requerido</small>
                </div>
            </div>
        </div>

        <div class="form-group">
            <label for="hospital"><i class="fas fa-building"></i> Hospital:</label>
            <input formControlName="hospital" type="text" class="form-control" id="hospital"
                placeholder="Ej: san Agustín">
            <small *ngIf="registerForm.get('hospital').hasError('required') && registerForm.get('hospital').touched"
                class="text-danger">Este campo es requerido</small>
        </div>

        <div class="form-group">
            <label for="maplocation"><i class="fas fa-map-marker-alt"></i> Ubicación:</label>
            <input formControlName="address" class="form-control" ngx-google-places-autocomplete #placesRef="ngx-places"
                (onAddressChange)="handleAddressChange($event)" />
            <small *ngIf="registerForm.get('address').hasError('required') && registerForm.get('address').touched"
                class="text-danger">Este campo es requerido</small>
        </div>

        <div class="row">
            <div class="col-md-5">
                <div class="row">
                    <div class="col-md-12">
                        <div class="row">
                            <label for="day"><i class="fas fa-calendar-day"></i> Dias de atención:</label>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <select formControlName="initialDay" class="form-control" id="initialDay">
                                        <option selected>Lunes</option>
                                        <option>Martes</option>
                                        <option>Miercoles</option>
                                        <option>Jueves</option>
                                        <option>Viernes</option>
                                        <option>Sabado</option>
                                        <option>Domingo</option>
                                    </select>
                                    <small
                                        *ngIf="registerForm.get('initialDay').hasError('required') && registerForm.get('initialDay').touched"
                                        class="text-danger">Este campo es requerido</small>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <select formControlName="finalDay" class="form-control" id="finalDay">
                                        <option>Lunes</option>
                                        <option>Martes</option>
                                        <option>Miercoles</option>
                                        <option>Jueves</option>
                                        <option>Viernes</option>
                                        <option>Sabado</option>
                                        <option selected>Domingo</option>
                                    </select>
                                    <small
                                        *ngIf="registerForm.get('finalDay').hasError('required') && registerForm.get('finalDay').touched"
                                        class="text-danger">Este campo es requerido</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-7">
                <div class="row">
                    <div class="col-md-12">
                        <div class="row">
                            <label class="ml-3" for="hourInitial"><i class="fas fa-clock"></i> Horario de
                                atención:</label>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <input formControlName="initialHour" type="time" class="form-control"
                                        id="hourInitial">
                                    <small
                                        *ngIf="registerForm.get('initialHour').hasError('required') && registerForm.get('initialHour').touched"
                                        class="text-danger">Este campo es requerido</small>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <input formControlName="finalHour" type="time" class="form-control" id="hour">
                                    <small
                                        *ngIf="registerForm.get('finalHour').hasError('required') && registerForm.get('finalHour').touched"
                                        class="text-danger">Este campo es requerido</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-group">
            <label for="email"><i class="fas fa-envelope"></i> Correo electronico:</label>
            <input formControlName="email" type="email" class="form-control" id="email" placeholder="Ingrese su correo">
            <small *ngIf="registerForm.get('email').hasError('required') && registerForm.get('email').touched"
                class="text-danger">Este campo es requerido</small>
            <small *ngIf="registerForm.get('email').hasError('email')"
                class="text-danger">E-mail no valido</small>
        </div>
        <div class="form-group">
            <label for="password"><i class="fas fa-key"></i> Contraseña:</label>
            <input formControlName="password" type="password" class="form-control" id="password" placeholder="Ingrese su contraseña">
            <small *ngIf="registerForm.get('password').hasError('required') && registerForm.get('password').touched"
                class="text-danger">Este campo es requerido</small>
        </div>


        <button [disabled]="registerForm.invalid" type="submit" class="btn btn-success w-100">Registrate</button>
    </form>
</div>
