import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MedicalBranchService } from '../../services/medical-branch.service';
import { SpecialityService } from '../../services/speciality.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-admin-modal-specilaity',
  templateUrl: './admin-modal-specilaity.component.html',
  styleUrls: ['./admin-modal-specilaity.component.css']
})
export class AdminModalSpecilaityComponent implements OnInit {

  @ViewChild('closebutton') closebutton;
  public SpecialityForm: FormGroup;
  public IsmodelShow: boolean = true; 
  public classValidName: string;
  public classValidBranch: string;
  public branches = [];
  @Output() refreshTable = new EventEmitter<any>();

  constructor(
    private _branchService: MedicalBranchService,
    private _specialityService: SpecialityService,
    private _builder: FormBuilder,
    private toastr: ToastrService) {
    this.SpecialityForm = this._builder.group({
      name: ['', Validators.required],
      branch: ['', Validators.compose([Validators.required])]
    });
    this.classValidName = "form-control";
    this.classValidBranch = "form-control";

  }

  ngOnInit(): void {
    this.getBranches();
  }

  getBranches() {
    this._branchService.getBranches().subscribe(
      response => {
        this.branches = response.branches;
      },
      error => {
        console.log(error);
      }
    )
  }

  onSubmit(form) {
    this._specialityService.speciality(form).subscribe(
      response => {
        this.closebutton.nativeElement.click();
        this.toastr.success(`${response.speciality.name} se ha registrado exitosamente`);
        this.refreshTable.emit();
      },
      error => {
        console.log(error);
      }
    )
  }

  reset() {
    this.classValidName = "form-control";
    this.classValidBranch = "form-control";
    this.SpecialityForm.reset();
  }

  validClassForm(param) {
    switch (param) {
      case 'name':
        if (this.SpecialityForm.get('name').hasError('required') && this.SpecialityForm.get('name').touched) {
          this.classValidName = "form-control is-invalid";
        } else {
          this.classValidName = "form-control is-valid";
        }
        break;
      case 'branch':
        if (this.SpecialityForm.get('branch').hasError('required') && this.SpecialityForm.get('branch').touched) {
          this.classValidBranch = "custom-select is-invalid";
        } else {
          this.classValidBranch = "custom-select is-valid";
        }
        break;
    }
  }


  onTouchClass(param): void {
    this.validClassForm(param);
  }

}
