import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AppComponent } from 'src/app/app.component';
import { AppointmentService } from 'src/app/services/appointment.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-table-medical',
  templateUrl: './table-medical.component.html',
  styleUrls: ['./table-medical.component.css']
})
export class TableMedicalComponent implements OnInit {
  public user: any;

  displayedColumns: string[] = ['Nombre', 'Edad', 'Telefono', 'Email', 'Patologia', 'Fecha'];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  dataResult: any[] = [{ "name": "juan", "age": 43, "phone": "234234234", "email": "michelle@hotmail.com", "pathology": "me duele la cabeza", "date": "2019-02-12" },
  { "name": "patric", "age": 43, "phone": "4921349217", "email": "michelle@hotmail.com", "pathology": "dolor de estomago", "date": "2019-02-12" },
  ];

  constructor(
    private _appointmentService: AppointmentService,
    private _userService: UserService,
    private appComponent: AppComponent
  ) { }

  ngOnInit(): void {
    this.getData();
    this.appComponent.getLoggedUser();
    this.appComponent.getNotificationsByDoctor();
  }

  getData() {

    this.user = this._userService.getLoggedUser();


    this._appointmentService.appointments(this.user._id).subscribe(
      response => {

        this.dataResult = response.appointments;
        this.dataSource = new MatTableDataSource(this.dataResult);
      },
      err => {
        console.log(err);

      }
    )
    this.dataSource = new MatTableDataSource(this.dataResult);
    this.dataSource.paginator = this.paginator;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  dataClick(num1, num2) {
    console.log(num1 + num2);


  }

}
