import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule }   from '@angular/forms';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {AgmCoreModule} from '@agm/core';
import { FullCalendarModule } from '@fullcalendar/angular'; // the main connector. must go first
import dayGridPlugin from '@fullcalendar/daygrid'; 
import interactionPlugin from '@fullcalendar/interaction'; 
import timeGridPlugin from '@fullcalendar/timegrid';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MomentModule } from 'angular2-moment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home/home.component';
import { MedicalBranchesComponent } from './components/home/medical-branches/medical-branches.component';
import { SpecialityComponent } from './components/speciality/speciality/speciality.component';
import { ListComponent } from './components/speciality/list/list.component';
import { MedicalInformationComponent } from './components/speciality/medical-information/medical-information.component';
import { MedicalComponent } from './components/medical/medical/medical.component';
import { MapLocationComponent } from './components/medical/map-location/map-location.component';
import { CalendarComponent } from './components/medical/calendar/calendar.component';
import { LoginComponent } from './components/login/login/login.component';
import { RegisterComponent } from './components/login/register/register.component';
import { TableMedicalComponent } from './components/tableMedical/table-medical/table-medical.component';
import { AdminModalSpecilaityComponent } from './components/admin-modal-specilaity/admin-modal-specilaity.component';
import { AdminTableComponent } from './components/admin-table/admin-table.component';
import { AdminBranchComponent } from './components/admin-branch/admin-branch.component';
import { AdminSpecialityComponent } from './components/admin-speciality/admin-speciality.component';
import { AdminModalBranchComponent } from './components/admin-modal-branch/admin-modal-branch.component';

import {MatTableModule} from '@angular/material/table';
import {MatInputModule} from '@angular/material/input';
import {MatPaginatorModule} from '@angular/material/paginator';

import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { ToastrModule } from 'ngx-toastr';
import { NgxStripeModule } from 'ngx-stripe';
import { NgxDropzoneModule } from 'ngx-dropzone';


import { TokenInterceptorService } from './services/token-interceptor.service';
import { PaymentsComponent } from './components/payments/payments.component';
import { PublicityComponent } from './components/publicity/publicity.component';
import { AdminUsersComponent } from './components/admin-users/admin-users.component';
import { AdminModalUsersComponent } from './components/admin-modal-users/admin-modal-users.component';
import { MedicalConfigurationComponent } from './components/medical-configuration/medical-configuration.component';

FullCalendarModule.registerPlugins([ // register FullCalendar plugins
  dayGridPlugin,
  interactionPlugin,
  timeGridPlugin
]);
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    MedicalBranchesComponent,
    SpecialityComponent,
    ListComponent,
    MedicalInformationComponent,
    MedicalComponent,
    MapLocationComponent,
    CalendarComponent,
    LoginComponent,
    RegisterComponent,
    TableMedicalComponent,
    AdminTableComponent,
    AdminBranchComponent,
    AdminSpecialityComponent,
    AdminModalBranchComponent,
    AdminModalSpecilaityComponent,
    PaymentsComponent,
    PublicityComponent,
    AdminUsersComponent,
    AdminModalUsersComponent,
    MedicalConfigurationComponent,
  ],
  imports: [
  BrowserModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    ReactiveFormsModule,
    FormsModule,
    MomentModule,
    MatTableModule,
    AppRoutingModule,
    GooglePlaceModule,
    MatInputModule,
    FullCalendarModule,
    MatPaginatorModule,
    NgxDropzoneModule,
    BrowserAnimationsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyA6dVnm6Z4SNNMrUYupr1Mon-at_0DFWmY'
    }),
    NgxStripeModule.forRoot('pk_live_51J2nMRLEKbkHm5FqwhARLVhzvp3o4j2SQPcbINZWQkGihSw8cZQaVymK0lM2fPqtPe58KE5rbJn8bVfyzuZTyOWB00XKMYzYxf'),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
